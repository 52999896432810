// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import { getMessaging, onBackgroundMessage } from 'firebase/messaging';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import './index.css';

// Firebase configuration from your .env or firebase.js
const firebaseConfig = {
  apiKey: "AIzaSyDi2C1D2x9yah5E-VFg023CP7LpNjcaR9Y",
  authDomain: "chat-app-b7301.firebaseapp.com",
  databaseURL: "https://chat-app-b7301-default-rtdb.firebaseio.com",
  projectId: "chat-app-b7301",
  storageBucket: "chat-app-b7301.appspot.com",
  messagingSenderId: "507382479061",
  appId: "1:507382479061:web:7cbccdf256d96b81c64a5c",
  measurementId: "G-4SLQKG0XLD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      // Initialize messaging with the service worker
      const messaging = getMessaging(app);
      console.log('FCM and SW registered successfully');
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

// Initialize app
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);