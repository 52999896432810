// Enhanced Soccer Knowledge Base

export const soccerQuestions = [
    // Messi Player History Questions
    {
      id: 'messi1',
      text: "Where was Lionel Messi born?",
      options: ["Madrid, Spain", "Buenos Aires, Argentina", "Paris, France", "Rosario, Argentina"],
      correct: 3,
      topic: "player-history",
      difficulty: 2,
      explanation: "Messi was born in Rosario, Argentina. It's a city known for producing great soccer players!",
      funFact: "Messi loved soccer from a very young age and always dreamed of being a professional player."
    },
    {
      id: 'messi2',
      text: "What special challenge did Messi face as a kid?",
      options: ["Couldn't run fast", "Had a hormone growth problem", "Was afraid of the ball", "Didn't like soccer"],
      correct: 1,
      topic: "player-history",
      difficulty: 2,
      explanation: "Messi had a hormone growth problem that made him shorter than other kids. Doctors helped him with special medicine.",
      funFact: "Even with this challenge, Messi became one of the best soccer players in the world!"
    },
    {
      id: 'messi3',
      text: "How many Ballon d'Or awards has Messi won?",
      options: ["5", "6", "7", "8"],
      correct: 2,
      topic: "player-achievements",
      difficulty: 3,
      explanation: "Messi has won the Ballon d'Or 7 times, which shows he's one of the greatest players ever!",
      funFact: "The Ballon d'Or is like a super special trophy for the best soccer player of the year."
    },
    {
      id: 'messi4',
      text: "What big tournament did Messi win with Argentina in 2022?",
      options: ["Euro Cup", "Champions League", "World Cup", "FA Cup"],
      correct: 2,
      topic: "recent-achievements",
      difficulty: 3,
      explanation: "Messi helped Argentina win the 2022 FIFA World Cup in an exciting final against France!",
      funFact: "This was a dream come true for Messi and millions of fans in Argentina!"
    },
  
    // Soccer Basics Questions
    {
      id: 'soccer1',
      text: "How many points do you get for winning a soccer game?",
      options: ["1", "2", "3", "0"],
      correct: 2,
      topic: "soccer-rules",
      difficulty: 1,
      explanation: "In most soccer leagues, a team gets 3 points for a win, 1 point for a draw, and 0 points for a loss.",
      funFact: "This points system helps decide which team is the champion of the league!"
    },
    {
      id: 'soccer2',
      text: "What is the big soccer tournament where top European teams play?",
      options: ["The Super Bowl", "The UEFA Champions League", "The NBA Finals", "The World Series"],
      correct: 1,
      topic: "soccer-tournaments",
      difficulty: 2,
      explanation: "The UEFA Champions League is the most important club competition in European soccer.",
      funFact: "Teams from different countries compete to be the best in Europe!"
    },
  
    // South African Soccer Questions
    {
      id: 'sa_soccer1',
      text: "Which South African player was known as 'The Chief'?",
      options: ["Benni McCarthy", "Lucas Radebe", "Steven Pienaar", "Doctor Khumalo"],
      correct: 1,
      topic: "soccer-players",
      difficulty: 3,
      explanation: "Lucas Radebe played for Leeds United and was nicknamed 'The Chief' for his leadership.",
      funFact: "Radebe inspired many young soccer players in South Africa!"
    },
    {
      id: 'sa_soccer2',
      text: "Which South African striker won the Champions League?",
      options: ["Shaun Bartlett", "Steven Pienaar", "Benni McCarthy", "Doctor Khumalo"],
      correct: 2,
      topic: "soccer-players",
      difficulty: 3,
      explanation: "Benni McCarthy won the UEFA Champions League with Porto and was a famous striker.",
      funFact: "McCarthy was one of the most successful South African soccer players internationally!"
    }
  ];
  
  export const topics = {
    "player-history": {
      name: "Player History",
      icon: "👤",
      description: "Learn about famous players and their backgrounds"
    },
    "player-achievements": {
      name: "Player Achievements",
      icon: "🏆",
      description: "Discover amazing accomplishments of soccer stars"
    },
    "recent-achievements": {
      name: "Recent Achievements",
      icon: "🌟",
      description: "Latest and greatest soccer moments"
    },
    "soccer-rules": {
      name: "Soccer Rules",
      icon: "⚽",
      description: "Understand how soccer is played"
    },
    "soccer-tournaments": {
      name: "Soccer Tournaments",
      icon: "🏅",
      description: "Learn about big soccer competitions"
    },
    "soccer-players": {
      name: "Soccer Players",
      icon: "👥",
      description: "Explore stories of soccer legends"
    }
  };
  
  // Existing helper functions remain the same
  export const getRandomQuestions = (count = 1, topic = null, excludeIds = []) => {
    let availableQuestions = [...soccerQuestions];
    
    if (topic) {
      availableQuestions = availableQuestions.filter(q => q.topic === topic);
    }
  
    availableQuestions = availableQuestions.filter(q => !excludeIds.includes(q.id));
    availableQuestions.sort(() => Math.random() - 0.5);
  
    return availableQuestions.slice(0, count);
  };
  
  export const getNextQuestion = (answeredQuestions = [], currentStreak = 0) => {
    const usedIds = answeredQuestions.map(q => q.id);
    const preferredDifficulty = Math.min(Math.floor(currentStreak / 3) + 1, 3);
    
    let availableQuestions = soccerQuestions.filter(q => !usedIds.includes(q.id));
    availableQuestions = availableQuestions.sort((a, b) => {
      const scoreA = calculateQuestionPriority(a, preferredDifficulty, answeredQuestions);
      const scoreB = calculateQuestionPriority(b, preferredDifficulty, answeredQuestions);
      return (scoreB + Math.random() * 0.3) - (scoreA + Math.random() * 0.3);
    });
  
    return availableQuestions[0];
  };
  
  const calculateQuestionPriority = (question, preferredDifficulty, answeredQuestions) => {
    let score = 0;
    score += 1 - Math.abs(question.difficulty - preferredDifficulty) * 0.3;
    const topicCount = answeredQuestions.filter(q => q.topic === question.topic).length;
    score += 1 / (topicCount + 1);
    score += Math.random() * 0.2;
    return score;
  };
  
  export const getTopicStats = (answeredQuestions, topic) => {
    const topicQuestions = answeredQuestions.filter(q => q.topic === topic);
    const correct = topicQuestions.filter(q => q.isCorrect).length;
    
    return {
      total: topicQuestions.length,
      correct,
      percentage: topicQuestions.length > 0 ? 
        Math.round((correct / topicQuestions.length) * 100) : 0
    };
  };
  
  export const saveHighScore = (scoreData) => {
    try {
      const highScores = getHighScores();
      highScores.push({
        score: scoreData.points,
        questions: scoreData.questions,
        date: scoreData.date.toISOString(),
      });
      
      highScores.sort((a, b) => b.score - a.score);
      const topScores = highScores.slice(0, 10);
      
      localStorage.setItem('soccerHighScores', JSON.stringify(topScores));
      return topScores;
    } catch (error) {
      console.error('Error saving high score:', error);
      return [];
    }
  };
  
  export const getHighScores = () => {
    try {
      return JSON.parse(localStorage.getItem('soccerHighScores') || '[]');
    } catch (error) {
      console.error('Error getting high scores:', error);
      return [];
    }
  };
  
  export default {
    soccerQuestions,
    topics,
    getRandomQuestions,
    getNextQuestion,
    getTopicStats,
    saveHighScore,
    getHighScores
  };