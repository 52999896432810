import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Upload, Download, Trash2, Lock, File, X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { firebaseService } from '../services/data/firebaseService';
import TopNavigation from '../components/ui/TopNavigation';
import { useTheme } from '../contexts/ThemeContext';

const FamilyVaultView = () => {
  const [documents, setDocuments] = useState([]);
  const [isLocked, setIsLocked] = useState(true);
  const [passcode, setPasscode] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [documentName, setDocumentName] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { theme, isDarkMode, setIsDarkMode } = useTheme();

  const verifyPasscode = async () => {
    try {
      const isValid = await firebaseService.verifyVaultPasscode(passcode);
      if (isValid) {
        setIsLocked(false);
        await fetchDocuments();
      } else {
        setError('Incorrect passcode');
      }
    } catch (error) {
      console.error('Passcode verification error:', error);
      setError('Failed to verify passcode');
    }
  };

  const fetchDocuments = async () => {
    try {
      setIsLoading(true);
      const docsSnapshot = await firebaseService.listFamilyDocuments();
      
      // Transform the snapshot into an array of documents with IDs
      const docsArray = [];
      docsSnapshot.forEach(doc => {
        docsArray.push({
          id: doc.key,
          ...doc.val()
        });
      });
      
      // Sort by most recent first
      docsArray.sort((a, b) => {
        return new Date(b.uploadedAt || 0) - new Date(a.uploadedAt || 0);
      });
      
      setDocuments(docsArray);
    } catch (error) {
      console.error('Failed to fetch documents:', error);
      setError('Could not retrieve documents');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = async () => {
    if (!uploadFile) {
      setError('Please select a file');
      return;
    }

    try {
      setIsLoading(true);
      setError('');
      
      await firebaseService.uploadFamilyDocument(uploadFile, {
        name: documentName || uploadFile.name,
        uploadedAt: new Date().toISOString(),
        uploadedBy: user.email
      });
      
      await fetchDocuments();
      setUploadFile(null);
      setDocumentName('');
    } catch (error) {
      console.error('Upload failed:', error);
      setError(error.message || 'Failed to upload document');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteDocument = async (documentId) => {
    try {
      setIsLoading(true);
      await firebaseService.deleteDocument(documentId);
      await fetchDocuments();
    } catch (error) {
      console.error('Delete failed:', error);
      setError('Failed to delete document');
    } finally {
      setIsLoading(false);
    }
  };

  // Call fetchDocuments when unlocked
  useEffect(() => {
    if (!isLocked) {
      fetchDocuments();
    }
  }, [isLocked]);

  return (
    <div className="min-h-screen flex flex-col">
      <TopNavigation
        theme={theme}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
      />

      <div className="flex-1 p-4">
        {isLocked ? (
          <div className="max-w-md mx-auto mt-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white/10 backdrop-blur-lg p-6 rounded-xl"
            >
              <h2 className="text-xl font-bold mb-4 flex items-center">
                <Lock className="mr-2" /> Family Vault
              </h2>
              
              {error && (
                <div className="bg-red-500/20 text-red-500 p-2 rounded-lg mb-4">
                  {error}
                </div>
              )}

              <input 
                type="password" 
                value={passcode}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, '');
                  setPasscode(numericValue.slice(0, 4));
                }}
                placeholder="Enter 4-digit passcode"
                maxLength={4}
                className="w-full p-2 rounded-lg bg-white/20 mb-4"
              />
              <button 
                onClick={verifyPasscode}
                className="w-full p-2 rounded-lg bg-blue-500 text-white"
                disabled={passcode.length !== 4}
              >
                Unlock Vault
              </button>
            </motion.div>
          </div>
        ) : (
          <div className="max-w-2xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white/10 backdrop-blur-lg p-6 rounded-xl mb-4"
            >
              <h2 className="text-xl font-bold mb-4 flex items-center">
                <File className="mr-2" /> Upload Document
              </h2>

              {error && (
                <div className="bg-red-500/20 text-red-500 p-2 rounded-lg mb-4">
                  {error}
                </div>
              )}

              <div className="flex gap-2 mb-4">
                <input 
                  type="file" 
                  onChange={(e) => setUploadFile(e.target.files[0])}
                  className="flex-1 p-2 rounded-lg bg-white/20"
                />
                <input 
                  type="text"
                  value={documentName}
                  onChange={(e) => setDocumentName(e.target.value)}
                  placeholder="Document Name (optional)"
                  className="flex-1 p-2 rounded-lg bg-white/20"
                />
                <button 
                  onClick={handleUpload}
                  disabled={!uploadFile || isLoading}
                  className="p-2 rounded-lg bg-blue-500 text-white disabled:opacity-50"
                >
                  {isLoading ? 'Uploading...' : 'Upload'}
                </button>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white/10 backdrop-blur-lg p-6 rounded-xl"
            >
              <h2 className="text-xl font-bold mb-4">Uploaded Documents</h2>
              
              {isLoading ? (
                <div className="text-center">Loading...</div>
              ) : documents.length === 0 ? (
                <div className="text-center text-gray-500">No documents uploaded</div>
              ) : (
                <div className="space-y-2">
                  <AnimatePresence>
                    {documents.map((doc) => (
                      <motion.div 
                        key={doc.id}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="flex justify-between items-center bg-white/20 p-3 rounded-lg"
                      >
                        <div>
                          <div className="font-medium">{doc.name}</div>
                          <div className="text-sm text-gray-500">
                            {doc.uploadedAt && new Date(doc.uploadedAt).toLocaleDateString()}
                            {doc.uploadedBy && ` • ${doc.uploadedBy}`}
                          </div>
                        </div>
                        <div className="flex gap-2">
                          <button 
                            onClick={() => window.open(doc.fileURL, '_blank')}
                            className="p-2 rounded-full hover:bg-white/20"
                            title="Download"
                          >
                            <Download className="w-5 h-5" />
                          </button>
                          {user.email === doc.uploadedBy && (
                            <button 
                              onClick={() => handleDeleteDocument(doc.id)}
                              className="p-2 rounded-full hover:bg-white/20 text-red-500"
                              title="Delete"
                            >
                              <Trash2 className="w-5 h-5" />
                            </button>
                          )}
                        </div>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
              )}
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FamilyVaultView;