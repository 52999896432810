// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { useTheme } from './contexts/ThemeContext';
import { ThemeProvider } from './contexts/ThemeContext';
import LoadingIndicator from './components/chat/LoadingIndicator';
import AssistantView from './views/AssistantView';
import FamilyView from './views/FamilyView';
import LoginView from './views/LoginView';
import SettingsView from './views/SettingsView';
import { AuthProvider } from './contexts/AuthContext';
import LearnView from './views/LearnView';
import FamilyVaultView from './views/FamilyVaultView';
import TorahStudyView from './views/TorahStudyView';

const requestNotificationPermission = async () => {
  if (!('Notification' in window)) {
    console.log('This browser does not support notifications');
    return;
  }

  try {
    const permission = await Notification.requestPermission();
    console.log('Notification permission:', permission);
  } catch (error) {
    console.error('Error requesting notification permission:', error);
  }
};

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const { theme, isDarkMode } = useTheme();

  React.useEffect(() => {
    if (user) {
      requestNotificationPermission();
    }
  }, [user]);
  
  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <LoadingIndicator theme={theme} isDarkMode={isDarkMode} />
      </div>
    );
  }
  
  return user ? children : <Navigate to="/login" />;
};

// Routes Component
const AppRoutes = () => {
  return (
    <div className="h-screen">
      <Routes>
        <Route path="/login" element={<LoginView />} />
        
        <Route path="/" element={
          <PrivateRoute>
            <AssistantView />
          </PrivateRoute>
        } />
        
        <Route path="/family" element={
          <PrivateRoute>
            <FamilyView />
          </PrivateRoute>
        } />

        <Route path="/settings" element={
          <PrivateRoute>
            <SettingsView />
          </PrivateRoute>
        } />
        
        <Route path="/learn" element={
          <PrivateRoute>
            <LearnView />
          </PrivateRoute>
        } />

<Route path="/vault" element={
          <PrivateRoute>
            <FamilyVaultView />
          </PrivateRoute>
        } />
        
        <Route path="/torah" element={
          <PrivateRoute>
            <TorahStudyView />
          </PrivateRoute>
        } />

        {/* Catch-all redirect */}
        <Route path="*" element={<Navigate to="/family" replace />} />
      </Routes>
    </div>
  );
};

// Main App Component
const App = () => {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>
          <AppRoutes />
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;