// src/components/layout/ChatLayout.jsx
import React, { useEffect } from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const ChatLayout = ({ children }) => {
  const { theme, isDarkMode } = useTheme();

  // Fix for mobile viewport height issues
  useEffect(() => {
    const setVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVH();

    window.addEventListener('resize', setVH);
    window.addEventListener('orientationchange', setVH);

    return () => {
      window.removeEventListener('resize', setVH);
      window.removeEventListener('orientationchange', setVH);
    };
  }, []);

  return (
    <div className="relative w-full" style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
      {/* Background Layer */}
      <div 
        className="fixed inset-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${isDarkMode ? theme.background.dark : theme.background.light})`,
          height: '100%',
          transform: 'translateZ(0)'
        }}
      />
      
      {/* Content Layer */}
      <div className="relative h-full w-full flex flex-col z-10 overflow-hidden">
        {children}
      </div>
    </div>
  );
};

export default ChatLayout;