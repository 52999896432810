import React, { createContext, useContext, useState, useEffect } from 'react';
import { firebaseService } from '../services/data/firebaseService';

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    let mounted = true;
    let authUnsubscribe = null;

    const initializeAuth = async () => {
      try {
        // Clear any previous errors
        setAuthError(null);

        // Check if iOS
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        
        if (isIOS) {
          // Log device info for debugging
          console.log('Initializing auth on iOS device:', navigator.userAgent);
          
          // Force a small delay on iOS to ensure proper initialization
          await new Promise(resolve => setTimeout(resolve, 100));
        }

        // Set up auth state listener
        authUnsubscribe = firebaseService.auth.onAuthStateChanged(
          (user) => {
            if (!mounted) return;

            if (user) {
              setUser({
                uid: user.uid,
                email: user.email,
                firstName: user.email.split('@')[0],
                photoURL: user.photoURL
              });
              console.log('Auth state changed: User authenticated');
            } else {
              setUser(null);
              console.log('Auth state changed: No user');
            }
            
            setLoading(false);
          },
          (error) => {
            console.error('Auth state error:', error);
            setAuthError(error);
            setLoading(false);
          }
        );

      } catch (error) {
        console.error('Auth initialization error:', error);
        if (mounted) {
          setAuthError(error);
          setLoading(false);
        }
      }
    };

    // Start initialization
    initializeAuth();

    // Failsafe: Force exit loading state after timeout
    const loadingTimeout = setTimeout(() => {
      if (mounted && loading) {
        console.warn('Auth loading timed out, forcing state update');
        setLoading(false);
      }
    }, 5000);

    // Cleanup
    return () => {
      mounted = false;
      clearTimeout(loadingTimeout);
      if (authUnsubscribe) {
        authUnsubscribe();
      }
    };
  }, []);

  const login = async (email, password) => {
    setAuthError(null);
    try {
      const result = await firebaseService.login(email, password);
      return result;
    } catch (error) {
      console.error('Login error:', error);
      setAuthError(error);
      throw error;
    }
  };

  const logout = async () => {
    setAuthError(null);
    try {
      await firebaseService.logout();
    } catch (error) {
      console.error('Logout error:', error);
      setAuthError(error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    login,
    logout,
    authError
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;