import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import TopNavigation from '../components/ui/TopNavigation';
import ChatBubble from '../components/chat/ChatBubble';
import InputArea from '../components/chat/InputArea';
import { firebaseService } from '../services/data/firebaseService';
import ChatLayout from '../components/layout/ChatLayout';

const FamilyView = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const { user } = useAuth();
  const { theme, isDarkMode, setIsDarkMode } = useTheme();
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);
  const [unreadCount, setUnreadCount] = useState(0);

// Clear badges when the component mounts and tab becomes visible
useEffect(() => {
  const clearBadges = async () => {
    if ('clearAppBadge' in navigator) {
      try {
        await navigator.clearAppBadge();
      } catch (error) {
        console.warn('Failed to clear badge:', error);
      }
    }
  };

  // Clear badges when component mounts
  clearBadges();

  // Clear badges when tab becomes visible
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      clearBadges();
    }
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);
  return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
}, []);

// Subscribe to messages
useEffect(() => {
  if (!user) return;

  const unsubscribe = firebaseService.subscribeFamilyChat((updatedMessages) => {
    const sortedMessages = updatedMessages.sort((a, b) => {
      const timestampA = a.timestamp ? new Date(a.timestamp).getTime() : 0;
      const timestampB = b.timestamp ? new Date(b.timestamp).getTime() : 0;
      return timestampA - timestampB;
    });
    setMessages(sortedMessages);
  });

  return () => unsubscribe && unsubscribe();
}, [user]);

// Add this effect after your message subscription
useEffect(() => {
  if (!user) return;
  
  const unsubscribe = firebaseService.subscribeToUnreadCount(
    user.uid,
    count => setUnreadCount(count)
  );

  return () => unsubscribe();
}, [user]);

// In FamilyView.jsx, modify the read state effect
useEffect(() => {
  if (!user || !messages.length || document.hidden) return;

  const timeoutId = setTimeout(async () => {
    const unreadMessages = messages
      .filter(msg => 
        msg.userId !== user.uid && 
        (!msg.readBy || !msg.readBy[user.uid])
      )
      .slice(-10);

    if (unreadMessages.length > 0) {
      await Promise.all(
        unreadMessages.map(msg =>
          firebaseService.updateMessage(user.uid, msg.id, { 
            readBy: { [user.uid]: true }
          }, 'family')
        )
      );

    }
  }, 2000);

  return () => clearTimeout(timeoutId);
}, [messages, user]);

// Group messages (keep this as is)
const groupedMessages = useMemo(() => {
  const groups = [];
  let currentGroup = [];
  
  messages.forEach((message, index) => {
    const prevMessage = messages[index - 1];
    
    if (
      !prevMessage ||
      prevMessage.userId !== message.userId ||
      Math.abs(new Date(message.timestamp) - new Date(prevMessage.timestamp)) > 120000
    ) {
      if (currentGroup.length > 0) {
        groups.push(currentGroup);
      }
      currentGroup = [message];
    } else {
      currentGroup.push(message);
    }
    
    if (index === messages.length - 1) {
      groups.push(currentGroup);
    }
  });
  
  return groups;
}, [messages]);
  // Improved scroll handling
  useEffect(() => {
    if (!messagesEndRef.current || !containerRef.current) return;

    const shouldAutoScroll = 
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
      containerRef.current.scrollHeight - 100;

    if (shouldAutoScroll) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);



  const handleSendMessage = async (content) => {
    if (!content.trim() || !user || isLoading) return;
  
    setIsLoading(true);
    try {
      await firebaseService.sendFamilyMessage(user.uid, content);
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await firebaseService.deleteMessage('family', messageId);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleTextToSpeech = (content) => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      return;
    }

    const utterance = new SpeechSynthesisUtterance(content);
    utterance.onend = () => setIsSpeaking(false);
    utterance.onstart = () => setIsSpeaking(true);
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <TopNavigation
        theme={theme}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
      />
  
      <ChatLayout theme={theme} isDarkMode={isDarkMode}>
        <div 
          ref={containerRef}
          className="h-full overflow-y-auto px-4 pb-20 scroll-smooth"
        >
          <div className="max-w-3xl mx-auto space-y-2 pt-4">
            <AnimatePresence initial={false}>
              {groupedMessages.map((group, groupIndex) => (
                <motion.div key={group[0].id} className="space-y-1">
                  {group.map((message, messageIndex) => (
                    <ChatBubble
                      key={message.id}
                      message={message}
                      isCurrentUser={message.userId === user.uid}
                      isFirstInGroup={messageIndex === 0}
                      isLastInGroup={messageIndex === group.length - 1}
                      theme={theme}
                      isDarkMode={isDarkMode}
                      onDoubleTap={handleTextToSpeech}
                      onDelete={
                        message.userId === user.uid
                          ? () => handleDeleteMessage(message.id)
                          : undefined
                      }
                    />
                  ))}
                </motion.div>
              ))}
            </AnimatePresence>
            
            {isLoading && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="flex justify-center"
            >
              <div className="loading-indicator" />
            </motion.div>
          )}
          <div ref={messagesEndRef} className="h-px" />
        </div>
      </div>

      <InputArea
        onSendMessage={handleSendMessage}
        isLoading={isLoading}
        theme={theme}
        isDarkMode={isDarkMode}
      />
    </ChatLayout>
  </div>
);
  
};

export default FamilyView;