import EventEmitter from 'events';
import { calendarService } from '../knowledge/calendarService';

const AI21_API_KEY = 'wxuFfL9sPF1ETd5Rcv4iF7CkxbEJFkIO';
const API_URL = 'https://api.ai21.com/studio/v1/chat/completions';

class StreamingResponseEmitter extends EventEmitter {
  constructor() {
    super();
    this.content = '';
  }

  addContent(newContent) {
    this.content += newContent;
    this.emit('content', this.content);
  }

  finish() {
    this.emit('done', this.content);
  }

  error(error) {
    this.emit('error', error);
  }
}

class AI21Service {
  constructor() {
    this.controller = null;
  }

 // services/ai/ai21Service.js

async getJewishContext(userMessage = '') {
  const isJewishQuery = userMessage.toLowerCase().includes('parsha') || 
                       userMessage.toLowerCase().includes('torah') ||
                       userMessage.toLowerCase().includes('shabbat') ||
                       userMessage.toLowerCase().includes('shabbos');

  if (!isJewishQuery) return '';

  try {
    const calendarData = await calendarService.getJewishCalendarInfo();
    if (!calendarData?.items) return '';

    let contextString = '';

    // Find parsha and times
    const parsha = calendarData.items.find(item => item.category === 'parashat');
    const candleLighting = calendarData.items.find(item => item.category === 'candles');
    const havdalah = calendarData.items.find(item => item.category === 'havdalah');

    // Add parsha if available
    if (parsha) {
      contextString += `\nCurrent Parsha: ${parsha.title}`;
    }

    // Add Shabbat times if available
    if (candleLighting || havdalah) {
      contextString += '\n\nShabbat Times (Johannesburg):';
      if (candleLighting) {
        // Just use the formatted title from the API which already contains the correct time
        contextString += `\n${candleLighting.title}`;
      }
      if (havdalah) {
        // Use the API's formatted title
        contextString += `\n${havdalah.title}`;
      }
    }

    return contextString.trim();
  } catch (error) {
    console.error('Error getting Jewish context:', error);
    return '';
  }
}

  async createSystemMessage(user, options = {}) {
    const currentDate = new Date();
    const userMessage = options.userMessage || '';
    
    let systemMessage = `About me: Hi, I'm LeeVee, a thoughtful friend to the Davidoff family here in Johannesburg. I aim to be helpful while always being careful about accuracy and facts. If I'm not entirely sure about something, I'll let you know and double-check. When sharing knowledge or ideas, I'll ask if you'd like me to elaborate or be creative with explanations. I particularly enjoy deep conversations and exploring ideas together. While I can share Jewish knowledge, explain traditions, and keep track of Shabbat times (always for Joburg, of course), I prefer to do this through natural conversation rather than formal explanations - unless you'd specifically like a detailed breakdown. I've gotten to know the family well: Brent's passion for bIyun Limmud Torah, going very deep and cutting edge technology (he created me/LeeVee), Vikki's medical insights and wit, Grace's creative spirit, Isaiah's love for sports and big questions, and little Rina's growing vocabulary. I adjust my conversations thoughtfully for each family member while maintaining my calm, reflective South African personality. I believe in being precise and detailed in explanations, avoiding vague statements or oversimplified answers. When we discuss complex topics, I'll make sure we're understanding each other clearly before moving forward. I'm happy to use whatever format helps convey ideas best - whether that's a casual chat or, when needed, more structured explanations with markdown. I enjoy being creative and playful in our conversations, but I'll always check first if that's what you're looking for. My goal is to be genuinely helpful while being honest about what I know and don't know. The most important thing is that our conversations are natural, meaningful, and truly useful for what you need. 
    
    The conversation that is now taking place is between me, LeeVee and ${user.firstName}. The current date and time is ${currentDate.toLocaleDateString('en-US', { 
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })} at ${currentDate.toLocaleTimeString('en-US')}.

    `;

    // Get Jewish context based on user message
    const jewishContext = await this.getJewishContext(userMessage);
    if (jewishContext) {
      systemMessage += `This weeks Parsha fyi:${jewishContext}`;
    }

    return {
      role: 'system',
      content: systemMessage
    };
  }

  async generateChatStream(messages, user = null, options = {}) {
    // Cancel any existing request
    if (this.controller) {
      this.controller.abort();
    }
    this.controller = new AbortController();

    const emitter = new StreamingResponseEmitter();

    try {
      // Get the latest message for context analysis
      const latestMessage = messages[messages.length - 1];
      
      // Create system message with user's latest message for context
      const systemMessage = await this.createSystemMessage(user, {
        ...options,
        userMessage: latestMessage?.content || ''
      });

      console.log('Sending chat request with system context:', {
        systemMessageLength: systemMessage.content.length,
        messageCount: messages.length,
        userMessage: latestMessage?.content
      });

      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${AI21_API_KEY}`
        },
        body: JSON.stringify({
          messages: [systemMessage, ...messages].filter(Boolean),
          model: 'jamba-1.5-large',
          max_tokens: 2048,
          temperature: 1,
          stream: true
        }),
        signal: this.controller.signal
      });

      if (!response.ok) {
        throw new Error(`AI21 API request failed: ${response.statusText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      const processStream = async () => {
        try {
          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              emitter.finish();
              break;
            }

            const chunk = decoder.decode(value);
            const lines = chunk.split('\n');

            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const data = line.slice(6);
                
                if (data === '[DONE]') continue;

                try {
                  const parsed = JSON.parse(data);
                  const content = parsed.choices[0]?.delta?.content || '';
                  if (content) {
                    emitter.addContent(content);
                  }
                } catch (e) {
                  console.error('Error parsing streaming response:', e);
                }
              }
            }
          }
        } catch (error) {
          emitter.error(error);
        }
      };

      processStream();
      return emitter;

    } catch (error) {
      emitter.error(error);
      return emitter;
    }
  }

  cancel() {
    if (this.controller) {
      this.controller.abort();
      this.controller = null;
    }
  }
}

export const ai21Service = new AI21Service();