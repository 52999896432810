// services/knowledge/calendarService.js
class CalendarService {
  constructor() {
    this.baseUrl = 'https://www.hebcal.com/hebcal';
    this.defaultParams = {
      v: 1,                    // API version
      cfg: 'json',             // Output format
      maj: 'off',              // Major holidays off
      min: 'off',              // Minor holidays off
      mod: 'off',              // Modern holidays off
      nx: 'off',              // Rosh Chodesh off
      mf: 'off',              // Minor fasts off
      ss: 'off',              // Special Shabbatot off
      s: 'on',                // Only Parashat ha-Shavuah on
      c: 'on',                // Candle lighting times on
      M: 'on',                // Havdalah times on
      geo: 'geoname',         // Use geoname for location
      geonameid: '3448439',   // Johannesburg
      i: 'off',               // Use Diaspora schedule
      b: 18,                  // Candle lighting 18 minutes before sunset
      leyning: 'off'          // Don't need full Torah reading details
    };
  }

  buildUrl(params = {}) {
    const queryParams = new URLSearchParams({
      ...this.defaultParams,
      ...params
    });
    return `${this.baseUrl}?${queryParams.toString()}`;
  }

  async getJewishCalendarInfo(startDate = null, endDate = null) {
    try {
      // Calculate date range for current week if not provided
      if (!startDate || !endDate) {
        const now = new Date();
        const dayOfWeek = now.getDay();
        startDate = new Date(now);
        startDate.setDate(now.getDate() - dayOfWeek);
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
      }

      // Format dates for API
      const formattedStart = startDate.toISOString().split('T')[0];
      const formattedEnd = endDate.toISOString().split('T')[0];

      const url = this.buildUrl({
        start: formattedStart,
        end: formattedEnd
      });

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Calendar API error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Calendar data received:', data);
      return data;
    } catch (error) {
      console.error('Error fetching calendar data:', error);
      return null;
    }
  }
}

export const calendarService = new CalendarService();