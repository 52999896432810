import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2, Camera, User } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getAuth, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firebaseService } from '../../services/data/firebaseService';

const AvatarSelection = () => {
  const { user } = useAuth();
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(user?.photoURL);  // Track current avatar URL
  
  const handlePhotoUpload = async (file) => {
    setIsUploading(true);
    setError(null);

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `profile_photos/${user.uid}`);
      
      // Upload to Firebase Storage
      await uploadBytes(storageRef, file);
      
      // Get the download URL
      const photoURL = await getDownloadURL(storageRef);
      
      // Update auth profile
      const auth = getAuth();
      await updateProfile(auth.currentUser, {
        photoURL: photoURL
      });

      // Update database profile
      await firebaseService.updateUserDisplayInfo(auth.currentUser.uid, {
        photoURL: photoURL
      });

      // Update local state to trigger re-render
      setAvatarUrl(photoURL);

      // Force refresh the current user object
      await auth.currentUser.reload();

    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile picture');
    } finally {
      setIsUploading(false);
    }
  };

  // Handle file selection
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type.startsWith('image/')) {
      // Check file size (limit to 2MB)
      if (file.size > 2 * 1024 * 1024) {
        setError('Image must be smaller than 2MB');
        return;
      }
      
      await handlePhotoUpload(file);
    } else {
      setError('Please select an image file');
    }
  };

  return (
    <div className="w-full max-w-md mx-auto p-6">
      <div className="space-y-8">
        <div className="flex flex-col items-center gap-4">
          <div className="relative">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-32 h-32 rounded-full overflow-hidden border-4 border-white dark:border-gray-800 shadow-xl"
            >
              {avatarUrl ? (  // Use avatarUrl instead of user.photoURL
                <img 
                  src={avatarUrl} 
                  alt="Profile"
                  className="w-full h-full object-cover"
                  onError={() => {
                    setAvatarUrl(null);  // Reset if image fails to load
                    setError('Error loading profile image');
                  }}
                />
              ) : (
                <div className="w-full h-full bg-gradient-to-br from-purple-500 to-indigo-500 flex items-center justify-center">
                  <User className="w-16 h-16 text-white" />
                </div>
              )}
            </motion.div>

            {/* Upload Button */}
            <label 
              className="absolute bottom-0 right-0 p-2 rounded-full bg-blue-500 text-white cursor-pointer shadow-lg
                         hover:bg-blue-600 transition-colors duration-200"
            >
              <input 
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleFileSelect}
                disabled={isUploading}
              />
              {isUploading ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Camera className="w-5 h-5" />
              )}
            </label>
          </div>

          <div className="text-center">
            <h3 className="text-lg font-medium dark:text-white">
              {user?.displayName || user?.email?.split('@')[0]}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Click the camera icon to update your profile picture
            </p>
          </div>

          {/* Error Message */}
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="text-red-500 text-sm text-center"
              >
                {error}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default AvatarSelection;