// views/AssistantView.jsx
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import TopNavigation from '../components/ui/TopNavigation';
import ChatBubble from '../components/chat/ChatBubble';
import InputArea from '../components/chat/InputArea';
import LoadingIndicator from '../components/chat/LoadingIndicator';
import { firebaseService } from '../services/data/firebaseService';
import { ai21Service } from '../services/ai/ai21Service';
import ChatLayout from '../components/layout/ChatLayout';  // Add this import


const AssistantView = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const { user } = useAuth();
  const { theme, isDarkMode, setIsDarkMode } = useTheme();
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);
  const streamingMessageIdRef = useRef(null);

  // Subscribe to assistant chat messages
  useEffect(() => {
    if (!user) return;

    const unsubscribe = firebaseService.subscribeAssistantChat(user.uid, (updatedMessages) => {
      const sortedMessages = updatedMessages.sort((a, b) => {
        const timestampA = a.timestamp ? new Date(a.timestamp).getTime() : 0;
        const timestampB = b.timestamp ? new Date(b.timestamp).getTime() : 0;
        return timestampA - timestampB;
      });

      const processedMessages = sortedMessages.map(message => ({
        ...message,
        userName: message.role === 'assistant' ? 'Assistant' : user.firstName,
        userPhoto: message.role === 'assistant' ? '/assistant-avatar.png' : user.photoURL,
        userId: message.role === 'assistant' ? 'assistant' : user.uid,
        type: message.role === 'assistant' ? 'markdown' : 'text'
      }));

      setMessages(processedMessages);
    });

    return () => unsubscribe();
  }, [user]);

  // Group messages by user and time
  const groupedMessages = useMemo(() => {
    const groups = [];
    let currentGroup = [];
    
    messages.forEach((message, index) => {
      const prevMessage = messages[index - 1];
      
      if (
        !prevMessage ||
        prevMessage.role !== message.role ||
        Math.abs(new Date(message.timestamp) - new Date(prevMessage.timestamp)) > 120000
      ) {
        if (currentGroup.length > 0) {
          groups.push(currentGroup);
        }
        currentGroup = [message];
      } else {
        currentGroup.push(message);
      }
      
      if (index === messages.length - 1) {
        groups.push(currentGroup);
      }
    });
    
    return groups;
  }, [messages]);

  // Auto-scroll
  useEffect(() => {
    if (!messagesEndRef.current || !containerRef.current) return;

    const shouldAutoScroll = 
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
      containerRef.current.scrollHeight - 100;

    if (shouldAutoScroll || isLoading) {
      messagesEndRef.current.scrollIntoView({ 
        behavior: isLoading ? 'auto' : 'smooth' 
      });
    }
  }, [messages, isLoading]);

  const handleSendMessage = async (content) => {
    if (!content.trim() || !user || isLoading) return;
  
    setIsLoading(true);
    try {
      // Save user message
      await firebaseService.sendAssistantMessage(user.uid, content, 'user');
      
      // Create empty assistant message
      const messageId = await firebaseService.createStreamingMessage(user.uid);
      streamingMessageIdRef.current = messageId;
  
      // Prepare message history for AI21
      const messageHistory = messages
        .filter(msg => msg.status !== 'streaming') // Filter out any streaming messages
        .map(msg => ({
          role: msg.role,
          content: msg.content
        }));
  
      // Add the new user message to history
      messageHistory.push({ role: 'user', content });
  
      // Get streaming response with full history
      const emitter = await ai21Service.generateChatStream(
        messageHistory,
        user
      );
  
      let accumulatedContent = '';
  
      // Handle streaming updates
      emitter.on('content', async (content) => {
        accumulatedContent = content;
        await firebaseService.updateStreamingMessage(
          user.uid,
          streamingMessageIdRef.current,
          accumulatedContent
        );
      });
  
      // Handle completion
      emitter.on('done', async () => {
        await firebaseService.updateStreamingMessage(
          user.uid,
          streamingMessageIdRef.current,
          accumulatedContent,
          true
        );
        setIsLoading(false);
      });
  
      // Handle errors
      emitter.on('error', async (error) => {
        console.error('Streaming error:', error);
        await firebaseService.updateStreamingMessage(
          user.uid,
          streamingMessageIdRef.current,
          'Sorry, an error occurred while generating the response.',
          true
        );
        setIsLoading(false);
      });
  
    } catch (error) {
      console.error('Error in conversation:', error);
      setIsLoading(false);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    if (!user || !messageId) return;
    
    try {
      await firebaseService.deleteMessage('assistant', messageId, user.uid);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleTextToSpeech = (content) => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      return;
    }

    const utterance = new SpeechSynthesisUtterance(content);
    utterance.onend = () => setIsSpeaking(false);
    utterance.onstart = () => setIsSpeaking(true);
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <TopNavigation
        theme={theme}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
      />
  
      <ChatLayout theme={theme} isDarkMode={isDarkMode}>
        <div 
          ref={containerRef}
          className="h-full overflow-y-auto px-4 pb-20 scroll-smooth"
        >
          <div className="max-w-3xl mx-auto space-y-2 pt-4">
            <AnimatePresence initial={false}>
              {groupedMessages.map((group, groupIndex) => (
                <motion.div key={group[0].id} className="space-y-1">
                  {group.map((message, messageIndex) => (
                    <ChatBubble
                    key={message.id}
                    message={message}
                    isCurrentUser={message.role === 'user'}
                    isFirstInGroup={messageIndex === 0}
                    isLastInGroup={messageIndex === group.length - 1}
                    theme={theme}
                    isDarkMode={isDarkMode}
                    onDoubleTap={handleTextToSpeech}
                    onDelete={() => handleDeleteMessage(message.id)}
                    mode="assistant"
                  />
                  ))}
                </motion.div>
              ))}
            </AnimatePresence>
            
            {isLoading && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="flex justify-center"
            >
              <LoadingIndicator theme={theme} isDarkMode={isDarkMode} />
            </motion.div>
          )}
          <div ref={messagesEndRef} className="h-px" />
        </div>
      </div>

      <InputArea
        onSendMessage={handleSendMessage}
        isLoading={isLoading}
        theme={theme}
        isDarkMode={isDarkMode}
        placeholder="Ask me anything..."
      />
    </ChatLayout>
  </div>
);
};

export default AssistantView;