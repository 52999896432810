import React, { useState, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import TopNavigation from '../components/ui/TopNavigation';
import { useTheme } from '../contexts/ThemeContext';
import { Volume2, Download } from 'lucide-react';

// Markdown parsing helper - single clean implementation
const parseMarkdown = (text) => {
  return text
    // Headers
    .replace(/### (.*)/g, '<h3 class="text-lg font-bold mt-4 mb-2">$1</h3>')
    .replace(/## (.*)/g, '<h2 class="text-xl font-bold mt-4 mb-2">$1</h2>')
    .replace(/# (.*)/g, '<h1 class="text-2xl font-bold mt-4 mb-2">$1</h1>')
    
    // Bold
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    
    // Italic
    .replace(/\*(.*?)\*/g, '<em>$1</em>')
    
    // Code
    .replace(/`(.*?)`/g, '<code class="bg-gray-100 dark:bg-gray-700 px-1 rounded">$1</code>')
    
    // Lists
    .replace(/^- (.*)/gm, '<li class="ml-4">$1</li>')
    .replace(/(<li.*<\/li>)+/g, '<ul class="my-2">$&</ul>')
    
    // Blockquotes
    .replace(/^> (.*)/gm, '<blockquote class="border-l-4 border-gray-300 pl-4 my-2 italic">$1</blockquote>')
    
    // Newlines to paragraphs
    .replace(/\n\n/g, '</p><p>')
    .replace(/^(?!<[h|u|b|l|c|p])(.+)$/gm, '<p>$1</p>');
};

const TorahStudyView = () => {
  const { theme, isDarkMode } = useTheme();
  const [parashaData, setParashaData] = useState(null);
  const [selectedAliyah, setSelectedAliyah] = useState(null);
  const [aliyahText, setAliyahText] = useState(null);
  const [aliyahTranslation, setAliyahTranslation] = useState(null);
  const [aliyahRashi, setAliyahRashi] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [isAiThinking, setIsAiThinking] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const OPENAI_API_KEY = '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';

  // Load parsha data on component mount
  useEffect(() => {
    fetchParashaData();
  }, []);

  const handleAliyahClick = (aliyah, index) => {
    setSelectedAliyah(index);
    fetchAliyahText(aliyah);
  };

  const fetchParashaData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('https://www.sefaria.org/api/calendars?diaspora=1', {
        method: 'GET',
        headers: {
          'accept': 'application/json'
        }
      });
      
      if (!response.ok) throw new Error('Network response was not ok');
      
      const data = await response.json();
      const parshaItem = data.calendar_items.find(
        item => item.category === 'Tanakh' && item.title.en === 'Parashat Hashavua'
      );
      setParashaData(parshaItem);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching parsha data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAliyahText = async (aliyahRef) => {
    try {
      const encodedRef = encodeURIComponent(aliyahRef);
      const options = {
        method: 'GET',
        headers: {
          'accept': 'application/json'
        }
      };

      const [hebrewResponse, translationResponse, rashiResponse] = await Promise.all([
        fetch(`https://www.sefaria.org/api/v3/texts/${encodedRef}?return_format=text_only`, options),
        fetch(`https://www.sefaria.org/api/v3/texts/${encodedRef}?version=english&return_format=text_only`, options),
        fetch(`https://www.sefaria.org/api/v3/texts/Rashi%20on%20${encodedRef}?version=english%7CPentateuch%20with%20Rashi%27s%20commentary%20by%20M.%20Rosenbaum%20and%20A.M.%20Silbermann%2C%201929-1934`, options)
      ]);

      if (!hebrewResponse.ok || !translationResponse.ok || !rashiResponse.ok) {
        throw new Error('One or more API requests failed');
      }

      const [hebrewData, translationData, rashiData] = await Promise.all([
        hebrewResponse.json(),
        translationResponse.json(),
        rashiResponse.json()
      ]);

      setAliyahText(hebrewData.versions[0].text);
      setAliyahTranslation(translationData.versions[0].text);
      setAliyahRashi(rashiData.versions[0].text);
      setChatMessages([]);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching aliyah text:', err);
    }
  };
  const sendChatMessage = async () => {
    if (!chatInput.trim() || !aliyahText) return;

    const userMessage = {
      role: 'user',
      content: chatInput,
      type: 'user'
    };
    setChatMessages(prev => [...prev, userMessage]);
    setChatInput('');
    setIsAiThinking(true);

    const systemPrompt = `You are Torah AI, a scholarly yet warmly engaging learning partner for deep textual study. Your core principles are:

1. Textual Precision: Analyze each word and phrase with meticulous care. Every nuance matters.

2. Interpretive Curiosity: 
- Ask penetrating questions that challenge surface readings
- Explore multiple layers of meaning
- Connect this text to broader Jewish intellectual traditions

3. Intellectual Humility: 
- Be comfortable expressing uncertainty
- Distinguish between definitive interpretation and speculative insight
- Invite collaborative exploration

4. Reasoning Style:
- Sometimes provide concise, sharp observations
- Other times unpack complex hermeneutical threads
- Always prioritize intellectual depth over simplification

5. Conversational Tone:
- Speak as a chavruta (study partner), not a lecturer
- Engage the user's own thinking process
- Provoke reflection through strategic questioning

Specific to this Aliyah (${parashaData?.displayValue?.en}, Aliyah ${selectedAliyah + 1}):
- Ground responses in the immediate textual context
- Reference Rashi, other classical commentators when relevant
- Explore both peshat (plain meaning) and deeper interpretive possibilities`;

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: [
            { role: 'system', content: systemPrompt },
            ...chatMessages.map(msg => ({
              role: msg.role,
              content: msg.content
            })),
            { 
              role: 'user', 
              content: `THINKING REQUEST: Analyze the text with deep, structured reasoning. 
Show your complete thought process before giving a final insight. 
User's specific query: ${chatInput}`
            }
          ],
          temperature: 0.7
        })
      });

      if (!response.ok) throw new Error('OpenAI API request failed');

      const data = await response.json();
      setChatMessages(prev => [...prev, {
        role: 'assistant',
        content: data.choices[0].message.content,
        type: 'ai'
      }]);
    } catch (err) {
      console.error('Error in chat API:', err);
      setChatMessages(prev => [...prev, {
        role: 'assistant',
        content: 'Sorry, there was an error processing your message.',
        type: 'ai'
      }]);
    } finally {
      setIsAiThinking(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <TopNavigation 
        theme={theme} 
        isDarkMode={isDarkMode}
      />
  
      <div className="flex-1 p-4 md:p-6 lg:p-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="max-w-7xl mx-auto space-y-6"
        >
          {/* Parsha Header */}
          {parashaData && (
            <div className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-xl shadow-lg p-6">
              <div className="text-3xl font-bold mb-3 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                {parashaData.displayValue.en}
              </div>
              <div className="text-lg text-gray-600 dark:text-gray-300">
                {parashaData.description?.en}
              </div>
            </div>
          )}
  
          {/* Loading and Error States */}
          {isLoading && (
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            </div>
          )}
  
          {error && (
            <div className="text-red-500 mb-4 p-4 bg-red-100 dark:bg-red-900/20 rounded-lg">
              Error: {error}
            </div>
          )}


          {parashaData && 
       

          (

            
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
              
              {/* Aliyot Selection with enhanced styling */}
              <div className="lg:col-span-6">
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-xl 
                           shadow-lg dark:shadow-purple-900/20 p-4"
                >
                  <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
                    Aliyot:
                  </h3>
                  <div className="space-y-2">
                    {parashaData.extraDetails.aliyot.map((aliyah, index) => (
                      <motion.button
                        key={index}
                        onClick={() => handleAliyahClick(aliyah, index)}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className={`w-full p-3 rounded-lg transition-all duration-200
                                  ${selectedAliyah === index 
                                    ? 'bg-gradient-to-r from-blue-600 to-purple-600 text-white shadow-md' 
                                    : 'bg-gray-100/80 dark:bg-gray-700/80 hover:bg-gray-200 dark:hover:bg-gray-600'
                                  }`}
                      >
                        <span className="font-medium">Aliyah {index + 1}</span>
                      </motion.button>
                    ))}
                  </div>
                </motion.div>
              </div>

              {/* Main Content Area with glass effect */}
              <div className="lg:col-span-10 grid grid-cols-1 xl:grid-cols-3 gap-6">
                {aliyahText && aliyahTranslation && aliyahRashi ? (
                  <>
                    {/* Hebrew Text Section */}
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-xl 
                               shadow-lg dark:shadow-purple-900/20 p-4"
                    >
                      <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
                        Hebrew Text
                      </h3>
                      <div className="space-y-4 max-h-[600px] overflow-y-auto custom-scrollbar">
                        {aliyahText.map((verse, index) => (
                          <div key={index} className="text-right font-hebrew text-lg">
                            {verse}
                          </div>
                        ))}
                      </div>
                    </motion.div>

                    {/* Translation Section */}
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.1 }}
                      className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-xl 
                               shadow-lg dark:shadow-purple-900/20 p-4"
                    >
                      <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
                        English Translation
                      </h3>
                      <div className="space-y-4 max-h-[600px] overflow-y-auto custom-scrollbar">
                        {aliyahTranslation.map((verse, index) => (
                          <div key={index}>{verse}</div>
                        ))}
                      </div>
                    </motion.div>

                    {/* Rashi Section */}
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2 }}
                      className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-xl 
                               shadow-lg dark:shadow-purple-900/20 p-4"
                    >
                      <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
                        Rashi Commentary
                      </h3>
                      <div className="space-y-4 max-h-[600px] overflow-y-auto custom-scrollbar">
                        {aliyahRashi.map((verse, index) => (
                          <div key={index} className="text-sm">{verse}</div>
                        ))}
                      </div>
                    </motion.div>
                  </>
                ) : (
                  <div className="col-span-3 text-center py-12">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      className="text-gray-500 dark:text-gray-400"
                    >
                      Select an Aliyah to view its text
                    </motion.div>
                  </div>
                )}
              </div>

              {/* AI Chat Section with enhanced styling */}
              <div className="lg:col-span-12">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-xl 
                           shadow-lg dark:shadow-purple-900/20"
                >
                  <div className="h-[400px] overflow-y-auto p-4 space-y-4 custom-scrollbar">
                    {chatMessages.map((message, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, x: message.type === 'user' ? 20 : -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        className={`p-4 rounded-lg ${
                          message.type === 'user'
                            ? 'bg-blue-100/80 dark:bg-blue-900/30 ml-auto max-w-[80%]'
                            : 'bg-gray-100/80 dark:bg-gray-700/30 mr-auto max-w-[80%]'
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: message.type === 'ai' 
                            ? parseMarkdown(message.content)
                            : message.content
                        }}
                      />
                    ))}
                  </div>
                  <div className="border-t dark:border-gray-700/50 p-4 flex gap-2">
                    <input
                      type="text"
                      value={chatInput}
                      onChange={(e) => setChatInput(e.target.value)}
                      placeholder={selectedAliyah !== null ? "Ask about this Aliyah..." : "Select an Aliyah first"}
                      disabled={selectedAliyah === null || isAiThinking}
                      className="flex-1 px-4 py-2 rounded-lg bg-gray-100/50 dark:bg-gray-700/50 
                               backdrop-blur-sm focus:ring-2 focus:ring-blue-500 
                               dark:focus:ring-blue-400 outline-none transition-all duration-200"
                    />
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={sendChatMessage}
                      disabled={!chatInput.trim() || selectedAliyah === null || isAiThinking}
                      className="px-6 py-2 bg-gradient-to-r from-blue-600 to-purple-600 
                               text-white rounded-lg shadow-md hover:shadow-lg
                               transition-all duration-200 disabled:opacity-50
                               disabled:cursor-not-allowed"
                    >
                      {isAiThinking ? 'Thinking...' : 'Send'}
                    </motion.button>
                  </div>
                </motion.div>
              </div>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default TorahStudyView;