import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Lock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import AvatarSelection from '../components/settings/AvatarSelection';
import TopNavigation from '../components/ui/TopNavigation';
import { firebaseService } from '../services/data/firebaseService';

const SettingsView = () => {
  const navigate = useNavigate();
  const { theme, isDarkMode, setIsDarkMode } = useTheme();
  const [passcode, setPasscode] = useState('');
  const [confirmPasscode, setConfirmPasscode] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handlePasscodeSetup = async () => {
    // Reset previous messages
    setError('');
    setSuccessMessage('');

    // Validate passcode
    if (passcode.length !== 4) {
      setError('Passcode must be 4 digits');
      return;
    }

    if (passcode !== confirmPasscode) {
      setError('Passcodes do not match');
      return;
    }

    try {
      await firebaseService.setVaultPasscode(passcode);
      setSuccessMessage('Vault passcode set successfully!');
      // Clear inputs
      setPasscode('');
      setConfirmPasscode('');
    } catch (error) {
      console.error('Passcode setup error:', error);
      setError('Failed to set passcode');
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <TopNavigation
        theme={theme}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
        showBackButton={true}
        showMenuButton={false}
      />

      <div 
        className="flex-1 overflow-hidden relative"
        style={{
          backgroundImage: `url(${isDarkMode ? theme.background.dark : theme.background.light})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed'
        }}
      >
        <div className="max-w-3xl mx-auto px-4 py-6 space-y-6">
          {/* Avatar Selection */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg rounded-2xl shadow-xl p-6"
          >
            <AvatarSelection />
          </motion.div>

          {/* Vault Passcode Setup */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg rounded-2xl shadow-xl p-6"
          >
            <div className="flex items-center gap-3 mb-4">
              <Lock className="w-6 h-6 text-blue-500" />
              <h2 className="text-xl font-bold">Family Vault Passcode</h2>
            </div>

            {error && (
              <div className="bg-red-500/20 text-red-700 p-2 rounded-lg mb-4">
                {error}
              </div>
            )}

            {successMessage && (
              <div className="bg-green-500/20 text-green-700 p-2 rounded-lg mb-4">
                {successMessage}
              </div>
            )}

            <div className="space-y-4">
              <div>
                <label className="block mb-2 text-sm">New 4-Digit Passcode</label>
                <input
                  type="password"
                  value={passcode}
                  onChange={(e) => {
                    // Only allow numeric input
                    const numericValue = e.target.value.replace(/\D/g, '');
                    setPasscode(numericValue.slice(0, 4));
                  }}
                  placeholder="Enter 4-digit passcode"
                  maxLength={4}
                  className="w-full p-2 rounded-lg bg-gray-100 dark:bg-gray-700"
                />
              </div>

              <div>
                <label className="block mb-2 text-sm">Confirm Passcode</label>
                <input
                  type="password"
                  value={confirmPasscode}
                  onChange={(e) => {
                    // Only allow numeric input
                    const numericValue = e.target.value.replace(/\D/g, '');
                    setConfirmPasscode(numericValue.slice(0, 4));
                  }}
                  placeholder="Confirm 4-digit passcode"
                  maxLength={4}
                  className="w-full p-2 rounded-lg bg-gray-100 dark:bg-gray-700"
                />
              </div>

              <button
                onClick={handlePasscodeSetup}
                className="w-full p-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors"
              >
                Set Vault Passcode
              </button>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SettingsView;