import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Menu, X, Users, MessageSquare, Settings, LogOut, 
  Sun, Moon, ChevronLeft, Palette, Home,
  Trophy,
  Mountain
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useState, useEffect } from 'react';
import { firebaseService } from '../../services/data/firebaseService';


const TopNavigation = ({
  theme,
  isDarkMode,
  setIsDarkMode,
  setCurrentTheme,
  onlineUsers = {},
  showBackButton = false,
  showMenuButton = true
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isFamily = location.pathname === '/family';
  

  const themes = {
    clouds: { name: 'Clouds', icon: '☁️' },
    ocean: { name: 'Ocean', icon: '🌊' },
    forest: { name: 'Forest', icon: '🌿' }
  };

  const backgroundStyle = {
    backgroundColor: isDarkMode 
      ? 'rgba(0, 0, 0, 0.7)' 
      : 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(12px)',
    WebkitBackdropFilter: 'blur(12px)'
  };
// Add this effect to subscribe to unread counts
useEffect(() => {
  if (!user) return;

  const unsubscribe = firebaseService.subscribeToUnreadCount(
    user.uid,
    (count) => setUnreadCount(count)
  );

  return () => unsubscribe && unsubscribe();
}, [user]);

  // Online users display
  const OnlineUsers = () => {
    const onlineCount = Object.values(onlineUsers).filter(u => u.online).length;
    if (onlineCount === 0) return null;
    
    return (
      <motion.div 
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        className="flex -space-x-2"
      >
        {Object.entries(onlineUsers)
          .filter(([_, userData]) => userData.online)
          .slice(0, 3)
          .map(([uid, userData], index) => (
            <motion.div
              key={uid}
              className="w-8 h-8 rounded-full border-2 border-white dark:border-gray-800 relative"
              style={{ 
                backgroundColor: theme.colors.primary.main,
                zIndex: 3 - index 
              }}
              whileHover={{ scale: 1.1 }}
            >
              {userData.photoURL ? (
                <img 
                  src={userData.photoURL} 
                  alt={userData.displayName}
                  className="w-full h-full rounded-full object-cover"
                />
              ) : (
                <div className="w-full h-full rounded-full flex items-center justify-center text-white text-sm font-medium">
                  {userData.displayName?.[0]?.toUpperCase()}
                </div>
              )}
              <div className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 rounded-full border-2 border-white dark:border-gray-800" />
            </motion.div>
          ))}
      </motion.div>
    );
  };

   // Modify the MenuLink component to accept and show badge count
   const MenuLink = ({ to, icon: Icon, children, badgeCount }) => (
    <Link
      to={to}
      onClick={() => setIsMenuOpen(false)}
      className={`
        flex items-center gap-3 p-3 rounded-lg
        transition-colors duration-200 relative
        ${location.pathname === to 
          ? 'bg-primary-500 text-white'
          : 'hover:bg-white/10'
        }
      `}
    >
      <Icon className="w-5 h-5" />
      <span>{children}</span>
      {badgeCount > 0 && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="absolute right-2 top-1/2 -translate-y-1/2
                     bg-red-500 text-white text-xs font-bold
                     min-w-[20px] h-5 rounded-full
                     flex items-center justify-center px-1"
        >
          {badgeCount}
        </motion.div>
      )}
    </Link>
  );

  const ControlButton = ({ icon: Icon, onClick, label }) => (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}
      className="p-2 rounded-lg hover:bg-white/10 transition-colors duration-200"
      aria-label={label}
    >
      <Icon className="w-5 h-5" />
    </motion.button>
  );

  return (
    <>
      <motion.header
        layout
        className="sticky top-0 z-40 w-full"
        style={backgroundStyle}
      >
        <div className="max-w-7xl mx-auto px-4 h-16">
          <div className="flex items-center justify-between h-full">
            {/* Left Section */}
            <div className="flex items-center gap-4">
              {showBackButton ? (
                <ControlButton 
                  icon={ChevronLeft} 
                  onClick={() => navigate(-1)} 
                  label="Go back"
                />
              ) : showMenuButton && (
                <ControlButton 
                  icon={Menu} 
                  onClick={() => setIsMenuOpen(true)}
                  label="Open menu"
                />
              )}

              <div className="flex items-center gap-3">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="w-8 h-8 rounded-xl bg-gradient-to-br from-primary-500 to-primary-600"
                />
                <div className="flex flex-col">
                  <h1 className="text-lg font-medium leading-tight">
                    {isFamily ? 'Family Chat' : 'GoFamily Assistant'}
                  </h1>
                  {isFamily && onlineUsers && (
                    <p className="text-xs opacity-60">
                      {Object.values(onlineUsers).filter(u => u.online).length} online
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Right Section - Theme Controls */}
            <div className="flex items-center gap-2">
              {isFamily && <OnlineUsers />}
              
              

              {/* User Menu Button */}
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="w-8 h-8 rounded-full bg-primary-500 flex items-center justify-center"
                onClick={() => setIsMenuOpen(true)}
              >
                <span className="text-white font-medium">
                  {user?.email?.[0]?.toUpperCase()}
                </span>
              </motion.button>
            </div>
          </div>
        </div>
      </motion.header>

      {/* Slide-out Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50"
              onClick={() => setIsMenuOpen(false)}
            />
            
            <motion.div
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ type: 'spring', damping: 20 }}
              className="fixed left-0 top-0 bottom-0 w-80 z-50 
                bg-gradient-to-b from-gray-900 to-gray-800
                text-white shadow-xl"
            >
              {/* Menu Header */}
              <div className="flex items-center justify-between p-6 border-b border-white/10">
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-xl bg-gradient-to-br 
                    from-primary-500 to-primary-600 flex items-center justify-center"
                  >
                    <span className="text-white font-bold">
                      {user?.email?.[0]?.toUpperCase()}
                    </span>
                  </div>
                  <div>
                    <h2 className="font-medium text-white">GoFamily</h2>
                    <p className="text-sm text-gray-300">
                      {user?.email?.split('@')[0]}
                    </p>
                  </div>
                </div>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setIsMenuOpen(false)}
                  className="p-2 rounded-lg hover:bg-white/10 text-white"
                >
                  <X className="w-5 h-5" />
                </motion.button>
              </div>

              {/* Menu Links */}
  <nav className="p-4 space-y-2">
    <MenuLink to="/" icon={Home}>Home</MenuLink>
    <MenuLink 
      to="/family" 
      icon={Users} 
      badgeCount={unreadCount}
    >
      Family Chat
    </MenuLink>
    <MenuLink to="/assistant" icon={MessageSquare}>AI Assistant</MenuLink>
    <MenuLink to="/learn" icon={Trophy}>iKnow Challenge</MenuLink>
    <MenuLink to="/settings" icon={Settings}>Settings</MenuLink>
    <MenuLink to="/torah" icon={Mountain}>Torah Study</MenuLink>
  </nav>

              {/* Menu Footer */}
              <div className="absolute bottom-0 left-0 right-0 p-6 border-t border-white/10">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={async () => {
                    await logout();
                    navigate('/login');
                  }}
                  className="flex items-center gap-3 p-3 rounded-lg 
                    text-white hover:bg-white/10 w-full
                    transition-colors duration-200"
                >
                  <LogOut className="w-5 h-5" />
                  <span className="font-medium">Sign Out</span>
                </motion.button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default TopNavigation;